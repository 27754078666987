<template>
    <div>
      <vue-element-loading
        :active="appLoading"
        :is-full-screen="true"
        background-color="#FFFFFF"
        color="#68D389  "
        spinner="bar-fade-scale"
      />
      <ServerError v-if="ServerError" />
      <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: #ffffff">
              {{ msg }}
            </span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: #ffffff">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <v-layout wrap justify-center>
        <v-flex xs12 text-uppercase text-center py-10>
          <span class="itemHeading">General Instructions</span>
        </v-flex>
        <v-flex xs12 md12 px-md-3 px-lg-3 px-xl-3>
          <v-layout wrap>
            <v-flex xs12 md12>
              <v-layout wrap>
                <!-- <v-flex xs12>
                  <v-text-field
                    class="textField2"
                    dense
                    v-model="about.pageName"
                    outlined
                    label="Name of the Page"
                    required
                  ></v-text-field>
                </v-flex> -->
                <v-flex xs12>
                  <v-text-field
                    class="textField2"
                    dense
                    v-model="about.title"
                    outlined
                    label="Title"
                    required
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 md12>
              <ImageComp
                @stepper="winStepper"
                :singleImage="about.topImage"
                :pageId="about._id"
                :height="'2118'"
                :width="'8001'"
                :heading="'Upload Top Image'"
                :componentType="'topImage'"
              />
            </v-flex>
            <!-- <v-flex pt-5>
              <ImageComp
                @stepper="winStepper"
                :singleImage="about.rightImage"
                :pageId="about._id"
                :height="'2556'"
                :width="'2626'"
                :heading="'Upload Right Image'"
                :componentType="'rightImage'"
              />
            </v-flex> -->
            <v-flex xs12 md12>
              <v-layout wrap justify-center>
                <v-flex xs12 md12 pt-4 text-left>
                  <span class="textField1"
                    >Please add the content
                  </span>
                  <vue-editor
                    class="textField2 pt-1"
                    v-model="about.content"
                  ></vue-editor>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex md12 pt-4>
              <v-layout justify-end>
                <v-flex md3>
                  <v-btn
                    block
                    tile
                    color="#68D389"
                    light
                    :ripple="false"
                    depressed
                    @click="validateInput"
                    class="itemValue"
                  >
                    <span style="color: #ffffff">Save Changes </span>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>
  </template>
  <script>
  import axios from "axios";
  import ImageComp from "@/components/CommonComponents/singleImage";
  export default {
    components: {
      ImageComp,
    },
    data() {
      return {
        about: {
          title: null,
          content: null,
        },
        id: null,
        valid: false,
        imageArray: [],
        rightImage: null,
        topImage: null,
        coverImageArray: [],
        leftImage: null,
        appLoading: false,
        ServerError: false,
        showSnackBar: false,
        timeout: 5000,
        msg: null,
      };
    },
    mounted() {
      this.getData();
    },
    methods: {
      winStepper(window_data) {
        if (window_data.type == "topImage") {
          this.topImage = window_data.selectedFiles;
        }
        // if (window_data.type == "rightImage") {
        //   this.rightImage = window_data.selectedFiles;
        // }
      },
      validateInput() {
        if (!this.about.title) {
          this.msg = "Please Provide Title";
          this.showSnackBar = true;
          return;
        }
        //  else if (!this.about.pageName) {
        //   this.msg = "Please Provide Page name";
        //   this.showSnackBar = true;
        //   return;
        // }
         else if (!this.about.content) {
          this.msg = "Please Provide Content";
          this.showSnackBar = true;
          return;
        }
        // else if (!this.about.leftText) {
  
        //   this.msg = "Please Provide Left Text";
        //   this.showSnackBar = true;
        //   return;
        // } else if (!this.about.video) {
        //   this.msg = "Please Provide Video";
        //   this.showSnackBar = true;
        //   return;
        // }
        else if (!this.topImage && !this.about.topImage) {
          this.msg = "Please Upload Top Image";
          this.showSnackBar = true;
          return;
        }
        //  else if (!this.rightImage && !this.about.rightImage) {
        //   this.msg = "Please Upload Right Image";
        //   this.showSnackBar = true;
        //   return;
        // }
        else {
          this.addAbout();
        }
      },
      getData() {
        this.appLoading = true;
        axios({
          method: "GET",
          url: "/instructions/general/get",
          headers: {
            token: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            this.appLoading = false;
            this.about = response.data.data;
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      addAbout() {
        axios({
          method: "post",
          url: "/instructions/general/set",
          data: this.about,
          headers: {
            token: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            this.appLoading = false;
            if (response.data.status) {
              // this.msg = "Added Successully";
              Object.keys(this.about).forEach((key) => (this.about[key] = null));
              if (this.topImage) {
                let formData = new FormData();
                formData.append("image", this.topImage);
                this.uploadImage(formData, "/instructions/general/topimage");
              }
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.getData();
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            console.log(err);
          });
      },
  
      uploadImage(formData, url) {
        this.appLoading = true;
        axios({
          method: "POST",
          url: url,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            token: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            this.appLoading = false;
            if (response.data.status == true) {
              this.msg = "Added Successfully";
            } else {
              this.msg = "Can't Upload Image.. Please Try Again Later";
              this.showsnackbar = true;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.msg = "Can't Upload Image.. Please Try Again Later";
            this.showsnackbar = true;
            console.log(err);
          });
      },
    },
  };
  </script>